/*
  File: Assignment No. 2
  Student’s Name: John Raineir Po-on
  StudentID: 301363442
  Date: 01/21/2024
*/

import React from 'react'
import './skills.css'
import proj1 from '../../../proj1prog.png'
import proj2 from '../../../proj2database.png'
import proj3 from '../../../proj3software.png'

const Skills = () => {
  return (
    <section id='skills'>
        <span className='skillTitle'>Projects</span>
        <span className='skillDesc'></span>
        <div className='skillBars'>
            <div className='skillBar'>
                <img src={proj1} alt='' className='skillBarImg'/>
                <div className='skillBarText'>
                    <h2>Coffee Shop App</h2>
                    <p>I've effectively integrated the Order class and its related web form into our Coffee Shop App, providing a smooth and effective method for users to place and control their orders.</p>
                </div>
            </div>

            <div className='skillBar'>
                <img src={proj2} alt='' className='skillBarImg'/>
                <div className='skillBarText'>
                    <h2>Connecting Database to JDBC</h2>
                    <p>I helped enhance our database by assisting with different tasks and documenting the steps. Furthermore, I contributed to linking our database with JDBC, improving its performance, and guaranteeing smooth compatibility with our applications.</p>
                </div>
            </div>

            <div className='skillBar'>
                <img src={proj3} alt='' className='skillBarImg'/>
                <div className='skillBarText'>
                    <h2>Uber Software System Design</h2>
                    <p>In this project, I am responsible for developing and improving different visual aids like GUI, UML diagrams, and system diagrams to help everyone grasp our project better and achieve success together.</p>
                </div>
            </div>

        </div>
    </section>
  )
}

export default Skills