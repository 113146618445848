/*
  File: Assignment No. 2
  Student’s Name: John Raineir Po-on
  StudentID: 301363442
  Date: 01/21/2024
*/

import React from 'react'
import './intro.css'
import gb from '../../../gb.png'
//import btnImg from '../../../bag.png'
//import { Link } from 'react-scroll'

const Intro = () => {
  return (
    <section id='intro'>
      <div className='introContent'>
            <span className='hello' >Hello,</span>
            <span className='introText'>I'm <span className='introName'>John</span> <br/>
            
            <br></br>
            
            Mission</span>
            <p className='introPara'>As a student in software engineering, address real-world 
            <br></br>issues with creative software solutions. Work together,
            <br></br>become skilled in advanced technologies, and develop
            <br></br>applications that focus on the user. Show the results of 
            <br></br>effective teamwork and flexible development, 
            <br></br>creating a lasting influence on the industry.</p>
            
        </div>

        <img src={gb} alt='Profile' className='bg'/>

        
    </section>
  )
}

//<Link><button className='btn'><img src={btnImg} alt='Hire'className='btnImg'/>Hire Me</button></Link>

export default Intro

